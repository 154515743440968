/*
* Gabriel: We should start using a css preprocessor like sass/scss or less as we
*          start to grow out;
*/

/* utils */
.mt-1 {
  margin-top: 1rem;
}

.ml-1 {
  margin-right: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mtb-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mtb-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.highlight {
  background: #ffffd1;
}

/* Custom */

.App {
  display: grid;
}

.main {
  display: flex;
  flex-direction: row;
}

.admin-row {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
}

.admin-panel {
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.column-header-highlight {
  background: #48aff0;
}

/* New styling to be refactored */
.header-content-wrapper {
  margin: 0 auto;
}

.admin-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: 100vh;
  /* overflow-x: auto; // Remove for sticky sidebar to work */
}

.admin-content-wrapper {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  min-width: 800px;
  max-width: 900px;
}

.admin-page {
  padding: 0 5px 40px 40px;
  position: relative;
}

.menu-nav {
  margin: 0.5rem;
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  max-width: 15rem;
  width: 250px;
  padding: 25px 15px;
}

/* Data Table */
.cell-button {
  min-height: 20px;
}

.sidebar-library {
  padding: 5px 35px;
  width: 100%;
  min-width: 400px;
}

.sidebar-field {
  padding: 0.5rem 0;
}

.image-cell {
  max-width: 18px;
  width: 100%;
}

.selector {
  margin-bottom: 15px;
}

.selector .bp3-popover-target {
  max-width: 200px;
  width: 100%;
}

.selector .bp3-popover-target .bp3-multi-select {
  width: 100%;
}

/* Overrides */
.sticky .bp3-form-group {
  overflow-y: auto;
}

/*
* Weird thing where cards with elevation (box shadow)
* get cut off on the sides due to overflow
* This gives it some "breathing room" to display properly
*/
.sticky .bp3-form-group .bp3-card {
  margin-right: 1px;
  margin-left: 1px;
}

.DayPicker-Day--enrollmentStart {
  background-color: #d8e1e8;
}
